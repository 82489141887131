import { getProfileFromCookies } from '~/modules/auth';
import { cn } from '~/utils';

export interface AdsProps {
  class?: string;
  section:
    | 'home_728x90_1'
    | 'home_728x90_2'
    | 'free_this_week_728x90_1'
    | 'free_this_week_728x90_2'
    | 'become_a_creator_728x90_1'
    | 'genre_page_728x90_1'
    | 'category_page_728x90_1'
    | 'series_page_728x90_1'
    | 'flip_page_728x90_1'
    | 'spin_page_728x90_1';
}

export function Ads(props: AdsProps) {
  const sections: Record<AdsProps['section'], string> = {
    home_728x90_1: '9804967437',
    home_728x90_2: '1442712941',
    free_this_week_728x90_1: '9327327422',
    free_this_week_728x90_2: '5388082419',
    become_a_creator_728x90_1: '8149777438',
    genre_page_728x90_1: '5533005349',
    category_page_728x90_1: '2318866252',
    series_page_728x90_1: '5393404542',
    flip_page_728x90_1: '2767241203',
    spin_page_728x90_1: '1454159538',
  };

  const user = getProfileFromCookies();
  if (user && user.email.endsWith('dreamflare.ai')) {
    return (
      <ins
        class={cn(
          `bg-grey-100 max-w-[780px] h-[90px] mx-auto w-full -mt-12 -mb-8
          adsbygoogle`,
          props.class,
        )}
        // style='display:block'
        data-ad-client="ca-pub-8827626467590248"
        data-ad-slot={sections[props.section] ?? ''}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    );
  }
  return undefined;
}
